<template>
  <main>
    <Section1 class="child" />
    <Section2 class="child" />
    <Section21 class="child" />
    <Section5 class="child" />
    <Section6 class="child" />
    <Section8 class="child" />
  </main>
</template>

<script>
import Section1 from "../sections/Section-1.vue";
import Section2 from "../sections/Section-2.vue";
import Section21 from "../sections/Section-2.1.vue";
import Section6 from "../sections/Section-6.vue";
import Section8 from "../sections/Section-8.vue";
import Section5 from "../sections/Section-5.vue";

export default {
  components: {
    Section1,
    Section2,
    Section21,
    Section6,
    Section8,
    Section5,
  },
};
</script>

<style>
/* Scroll behaviour */
</style>
