<template>
  <section
    class="section5 flex row align-content-center justify-content-center"
  >
    <div
      class="flex col-md-12"
      style="position: relative; justify-content: center; align-items: center"
    >
      <h3 class="font-size-l text-center mt-5 mb-5">Aangesloten banen</h3>
      <img
        class="swipe-controls"
        src="../assets/swipe-control.png"
        style="position: absolute; right: 32px; top: 62px"
      />
    </div>

    <div class="scroll-container-golf-courts">
      <div
        id="golf-course-container"
        style="border-radius: 16px"
        v-for="(gfCourse, index) in golfCourses"
        :key="index"
      >
        <img
          :src="
            require(`../assets/section5/${
              gfCourse.imagePath.split('section5/')[1]
            }`)
          "
          style="width: 100%; height: 287.16px; border-radius: 16px"
        />

        <div class="">
          <h5 class="font-size-l golf-course-title text-left mt-4 mb-0">
            {{ gfCourse.name }}
          </h5>
          <p class="mb-0 font-size-xs golf-course-desc">
            {{ gfCourse.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import golfCourses from "../datasets/golf-courses.json";

export default {
  data() {
    var golfCourses = [];

    return {
      golfCourses,
      position: { left: 0, x: 0 },
      isDragging: false,
    };
  },
  mounted() {
    // get golf courses

    this.golfCourses = golfCourses;

    const scrollContainer = document.getElementsByClassName(
      "scroll-container-golf-courts"
    )[0];

    const mouseDownHandler = (e) => {
      this.isDragging = true;
      e.preventDefault();
      scrollContainer.style.cursor = "grabbing";
      scrollContainer.style.userSelect = "none";

      this.position = {
        left: scrollContainer.scrollLeft,
        x: e.clientX,
      };

      document.addEventListener("mousemove", this.mouseMoveHandler);
      document.addEventListener("mouseup", this.mouseUpHandler);
      document.addEventListener("mouseleave", this.mouseUpHandler);
      document.addEventListener("touchmove", this.mouseMoveHandler);
      document.addEventListener("touchend", this.mouseUpHandler);
    };

    this.mouseMoveHandler = (e) => {
      if (!this.isDragging) return;
      const dx = e.clientX - this.position.x;
      scrollContainer.scrollLeft = this.position.left - dx;
    };

    this.mouseUpHandler = () => {
      this.isDragging = false;
      scrollContainer.style.cursor = "grab";
      scrollContainer.style.removeProperty("user-select");

      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);
      document.removeEventListener("mouseleave", this.mouseUpHandler);
      document.removeEventListener("touchmove", this.mouseMoveHandler);
      document.removeEventListener("touchend", this.mouseUpHandler);
    };

    scrollContainer.addEventListener("mousedown", mouseDownHandler);
    // scrollContainer.addEventListener("touchstart", mouseDownHandler);
  },
};
</script>

<style scoped style="scss">
.section5 {
  background: #f87e5e;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.scroll-container-golf-courts {
  display: flex;
  column-gap: 32px;
  overflow-x: hidden;
  padding: 0 78px;

  cursor: grab;
  overflow: hidden;
}

#golf-course-container {
  min-height: 350px;
  min-width: 500px;
  border-radius: 16px;
}

.golf-course-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.golf-course-desc {
  line-height: 24px;
}

@media (max-width: 767.98px) {
  .section5 {
    height: auto;
    padding: 2rem 0;
  }

  .scroll-container-golf-courts {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 0 2rem !important;
  }

  #golf-course-container {
    min-width: 100%;
    border-radius: 16px;
  }

  .swipe-controls {
    display: none;
  }
}
</style>
