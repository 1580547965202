<template>
  <section class="section8">
    <div class="container d-flex flex-column align-items-center">
      <div class="row" style="width: 100%">
        <div class="col">
          <div class="section7-header text-center" style="width: 100%">
            <h5 class="text-header font-size-xs" style="margin-bottom: 2rem">
              Partners van The ACE Challenge.
            </h5>
            <div
              class="sponsor-image-container"
              style="display: flex; justify-content: center; column-gap: 100px"
            >
              <img src="../assets/section7/gassan.png" />
              <img src="../assets/section7/hodl.png" />
            </div>
            <div
              class="ace-info-container"
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 120px;
                width: 100%;
              "
            >
              <div
                class="ace-logo-container"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  row-gap: 32px;
                "
              >
                <img
                  src="../assets/section7/ace-white-logo.png"
                  style="width: 279px; height: 48px"
                />
                <span
                  class="font-size-sm"
                  style="
                    text-align: left;
                    line-height: 28px;
                    margin: 0;
                    text-decoration: none;
                    color: #ffffff;
                  "
                >
                  Vragen? Neem contact op met
                  <a
                    style="text-decoration: underline; color: #ffffff"
                    href="mailto:info@theacechallenge.com"
                    >info@theacechallenge.com</a
                  >
                  <br />
                  of bel ons op
                  <a
                    style="text-decoration: underline; color: #ffffff"
                    href="tel:020-2101633"
                    >020-2101633.</a
                  >
                </span>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  row-gap: 16px;
                "
              >
                <span
                  class="font-size-sm"
                  style="font-weight: 500; line-height: 24px"
                  >Download de app</span
                >
                <a
                  href="https://apps.apple.com/us/app/ace-challenge/id1569665781"
                >
                  <img
                    id="white-store-image"
                    src="../assets/section7/Mobile-app-store-badge.png"
                /></a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.acechallenge.app"
                >
                  <img
                    id="white-store-image"
                    src="../assets/section7/Mobile-play-store-badge.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Section-8",
  data() {
    return {
      sent: false,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      response: "",
    };
  },
  methods: {
    sendContactForm(e) {
      e.preventDefault();
      const data = {
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        phonenumber: this.phone,
        message: this.message,
      };

      axios
        .post("https://api.theacechallenge.com/api/contact", data)
        .then(() => {
          this.response = `Bericht verzonden!`;
        })
        .catch(() => {
          console.log("test fail");

          this.response = `Bericht niet verzonden!`;
        });

      setTimeout(() => {
        this.response = "";
        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.subject = "";
        this.message = "";
      }, 5000);
    },
  },
};
</script>

<style scoped>
.section8 {
  font-family: "Inter", sans-serif;
  background: #102f27;
  padding-bottom: 16rem;
  width: 100%;
}

.text-header {
  text-transform: none;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}

.text-body {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.form-group label,
.form-check label {
  font-size: 1rem;
  font-weight: 400;
  text-transform: none;
}

form {
  width: 50%;
}

.form-check {
  padding-left: calc(var(--bs-gutter-x) / 2);
}

form button {
  font-family: "Inter", sans-serif !important;
  width: 100%;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #000;
}

form button:focus,
form button:hover {
  color: #fff;
  background-color: #222;
}

form input,
form textarea {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.33);
  resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset !important;
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

form input:focus,
form textarea:focus,
form select:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn {
  background-color: #f87e5e;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  font-family: "Inter", "sans-serif" !important;
}

@media (max-width: 1199.98px) {
  form {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .sponsor-image-container {
    flex-direction: column;
    row-gap: 32px;
  }

  .ace-info-container {
    flex-direction: column;
    justify-content: center !important;
    row-gap: 32px;
  }

  #white-store-image {
    width: 100px;
  }

  .ace-logo-container {
    align-items: center !important;
  }

  .ace-logo-container span {
    text-align: center !important;
  }

  .text-body {
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  .section8 {
    padding-top: 8rem;
  }
}
</style>
