<template>
  <div class="section1-bg">
    <div class="container">
      <div class="row">
        <div id="left-box" class="col-xs-12 col-md-7" style="z-index: 999;">
          <div id="brand-text-box">
            <img
              src="../assets/section1/ACE_logo_type_right@1.5x.png"
              alt="The Ace Challenge"
            />
          </div>
          <div class="prize-text-box text-justify">
            <h5 class="font-size-l">Ready for a challenge?</h5>
            <span class="font-size-m">
              Het is simpel. Download de app, speel mee en win een Rolex.
              Kwalificeer je voor The ACE Cup en doe mee aan ons eindtoernooi
            </span>
          </div>
          <div id="stores-box">
            <a href="https://apps.apple.com/us/app/ace-challenge/id1569665781"
              ><img
                src="../assets/section1/Appstore_logo.png"
                alt="App Store"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.acechallenge.app"
              ><img
                src="../assets/section1/Googlestore_logo.png"
                alt="Google Store"
            /></a>
          </div>
        </div>
        <div id="right-box" class="col-xs-12 col-md-5" style="z-index: 999;">
          <div class="header-iphone-mockup">
            <img
              class="iphone-x"
              src="../assets/section1/iPhone14Pro-6@2x.png"
              alt="iPhone"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay-path"></div>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 136%;
  background: url("../assets/section1/background-shape.png") no-repeat;
  background-size: 105% 300%;
  background-position: 0 -4%;
  z-index: 0;
}

#right-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.section1-bg {
  background: url("../assets/section1/section1-bg.png"), rgba(16, 47, 39, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center, 0% -2%;
}

#left-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#brand-text-box {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  color: #ffffff;
}

.prize-text-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 3.125rem;
  line-height: normal;
  color: #ffffff;
}

.prize-text-box span {
  color: #ffffff;
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  padding-top: 0.875rem;
}

#stores-box {
  display: flex;
  justify-content: flex-start;
  width: 70%;
  padding-top: 50px;
  margin-bottom: 9.5rem;
}

.header-iphone-mockup .iphone-x {
  display: flex;
  justify-content: center;
  padding-top: 156px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .header-iphone-mockup .iphone-x {
    width: calc(360 / 780 * 730px);
    margin-top: 12rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991.98px) {
  .header-iphone-mockup .iphone-x {
    width: calc(360 / 780 * 650px);
    margin-top: 12rem;
  }
}

@media (max-width: 767.98px) {
  #right-box {
    align-items: center;
  }
  .header-iphone-mockup .iphone-x {
    height: 600px;
    width: calc(360 / 780 * 656px);
    margin-top: 4rem;
  }

  .container {
    padding-top: 5rem;
    text-align: center;
  }

  #left-box {
    align-items: center;
  }

  #stores-box {
    justify-content: center;
    margin-bottom: 5rem;
  }

  .prize-text-box {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #right-box {
    align-items: center;
  }

  .header-iphone-mockup .iphone-x {
    padding-top: 0;
    margin-top: 4rem;
  }
}
</style>
