<template>
  <section
    class="section6 flex row align-content-center justify-content-center"
  >
    <div
      class="flex col-md-12"
      style="position: relative; justify-content: center; align-items: center"
    >
      <h3 class="font-size-l text-center mt-5 mb-5">
        Deze golfers gingen je voor
      </h3>
      <img
        class="swipe-controls"
        src="../assets/swipe-control.png"
        style="position: absolute; right: 32px; top: 62px"
      />
    </div>

    <div class="scroll-container-videos" :ref="scrollContainer">
      <div
        id="videos-course-container"
        :ref="'videoContainer' + index"
        style="border-radius: 16px"
        v-for="(video, i) in videos"
        :key="i"
      >
        <video
          :id="'vid' + i"
          :ref="'video' + index"
          style="width: 96%; border-radius: 16px"
          controls
          poster="../assets/section6/video-poster.png"
        >
          <source :src="`${video}#t=${currentTime ?? 0},42`" type="video/mp4" />
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    const currentTime = 0;
    return {
      currentTime,
      videos: [
        "https://api.theacechallenge.com/storage/recordings/902d081b-e629-48f4-95d1-d8648926853f/recording_merged.mp4",
        "https://api.theacechallenge.com/storage/recordings/3e5a67b3-5fd5-4444-a1a7-f2955f730ade/recording_merged.mp4",
        "https://api.theacechallenge.com/storage/recordings/63b96039-2b9b-4718-b377-f829a6b65f71/recording_merged.mp4",
        "https://api.theacechallenge.com/storage/recordings/c6dfdeb4-db40-4a5e-a2db-b11f633b6511/recording_merged.mp4",
        "https://api.theacechallenge.com/storage/recordings/7d3db57c-2dd9-47ac-a704-18635c29ba24/recording_merged.mp4",
      ],
      isDragging: false,
      position: { left: 0, x: 0 },
    };
  },
  mounted() {
    const scrollContainer = document.getElementsByClassName(
      "scroll-container-videos"
    )[0];

    const mouseDownHandler = (e) => {
      this.isDragging = true;
      e.preventDefault();
      scrollContainer.style.cursor = "grabbing";
      scrollContainer.style.userSelect = "none";

      this.position = {
        left: scrollContainer.scrollLeft,
        x: e.clientX,
      };

      document.addEventListener("mousemove", this.mouseMoveHandler);
      document.addEventListener("mouseup", this.mouseUpHandler);
      document.addEventListener("mouseleave", this.mouseUpHandler);
      document.addEventListener("touchmove", this.mouseMoveHandler);
      document.addEventListener("touchend", this.mouseUpHandler);
    };

    this.mouseMoveHandler = (e) => {
      if (!this.isDragging) return;
      const dx = e.clientX - this.position.x;
      scrollContainer.scrollLeft = this.position.left - dx;
    };

    this.mouseUpHandler = () => {
      this.isDragging = false;
      scrollContainer.style.cursor = "grab";
      scrollContainer.style.removeProperty("user-select");

      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);
      document.removeEventListener("mouseleave", this.mouseUpHandler);
      document.removeEventListener("touchmove", this.mouseMoveHandler);
      document.removeEventListener("touchend", this.mouseUpHandler);
    };

    scrollContainer.addEventListener("mousedown", mouseDownHandler);
    scrollContainer.addEventListener("touchstart", mouseDownHandler);

    for (let i = 0; i < this.videos.length; i++) {
      const video = document.getElementById("vid" + i);
      if (video) {
        video.addEventListener(
          "play",
          function () {
            this.currentTime = 3;
          },
          false
        );
      }
    }
  },
};
</script>

<style scoped>
.section6 {
  background: #102f27;
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 100vh; */
  padding: 100px 0 160px 0;
}

.scroll-container-videos {
  display: flex;
  column-gap: 32px;
  overflow-x: hidden;
  padding: 0 78px;

  cursor: grab;
  overflow: hidden;
}

.scroll-container-videos::-webkit-scrollbar {
  display: none;
}

#videos-course-container {
  min-height: 255px;
  min-width: 470px;
  border-radius: 16px;
}

@media (max-width: 767.98px) {
  .scroll-container-videos {
    display: flex;
    flex-direction: column;
    padding: 0 2rem !important;
  }

  #videos-course-container {
    min-width: 100%;
    border-radius: 16px;
  }

  .swipe-controls {
    display: none;
  }
}
</style>
