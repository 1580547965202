<template>
  <the-header class="heading-temp"></the-header>
  <div class="green-bg" style="z-index: 9999">
    <the-main></the-main>
  </div>
  <the-footer class="footer-temp"></the-footer>
</template>

<script>
import TheHeader from "./layout/TheHeader.vue";
import TheMain from "./layout/TheMain.vue";
import TheFooter from "./layout/TheFooter.vue";

export default {
  components: {
    TheHeader,
    TheMain,
    TheFooter,
  },
  created() {
    document.title = "The Ace Challenge";
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

#app {
  overflow: hidden;
}

.din {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.green-bg {
  background-image: url("./assets/background.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -9rem;
}

section {
  background: url(""), rgb(255, 255, 255);
  background-position: 0% -2%;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  font-family: "Futura-PTExtraBold", Futura, sans-serif !important;
  font-stretch: condensed;
  font-weight: bold;
  color: #fff !important;
  line-height: 2.5rem !important;
}

#stores-box {
  width: max-content;
}

#stores-box img {
  height: 3.1rem;
  margin-right: 2rem;
}

.font-size-xl {
  font-family: "Inter", sans-serif;
  font-size: 5rem;
}

.font-size-l {
  font-family: "Inter", sans-serif;
  font-size: 3rem;
  font-weight: 600;
}

.font-size-m {
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.font-size-s {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
}
.font-size-xs {
  font-family: "Inter", sans-serif;
  font-size: 1rem !important;
}
.font-size-xxs {
  font-family: "Inter", sans-serif;
  font-size: 0.85rem !important;
}

.overlay-path img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  #stores-box img {
    height: 2.5rem;
    margin-right: 2rem;
  }

  .font-size-xl {
    font-family: "Inter", sans-serif;
    font-size: 4rem;
  }

  .font-size-l {
    font-family: "Inter", sans-serif;
    font-size: 2.5rem;
    font-weight: 800;
  }

  .font-size-m {
    font-size: 1.25rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }

  .font-size-s {
    font-family: "Inter", sans-serif;
    font-size: 1.25rem;
  }

  .font-size-xs {
    font-family: "Inter", sans-serif;
    font-size: 1.15rem;
  }

  .font-size-xxs {
    font-family: "Inter", sans-serif;
    font-size: 0.8rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991.98px) {
  #stores-box img {
    height: 2rem;
    margin-right: 1rem;
  }
}

@media (max-width: 767.98px) {
  #stores-box img {
    height: 3.1rem;
    margin-right: 2rem;
  }
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #stores-box img {
    height: 2rem;
    margin-right: 1rem;
  }
  .font-size-xl {
    font-family: "Inter", sans-serif;
    font-size: 2.5rem;
  }

  .font-size-l {
    font-family: "Inter", sans-serif;
    font-size: 2rem;
    font-weight: 800;
  }

  .font-size-m {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 1.05rem;
  }

  .font-size-s {
    font-family: "Inter", sans-serif;
    font-size: 1.15rem;
  }

  .font-size-xs {
    font-family: "Inter", sans-serif;
    font-size: 1.1rem;
  }

  .font-size-xxs {
    font-family: "Inter", sans-serif;
    font-size: 0.75rem;
  }
}
</style>
